<template>
  <div>
        <!-- 脚部 -->
    <div class="footer">
      <div class="bottom">
        <div class="wrapper">
          <div class="content_foot">
            <div class="right">
              <p class="right_p1">
                版权：北京新元互动科技有限公司
                <span>北京市朝阳区东十里堡路1号楼10层1001室</span>
                <span>  邮箱：linhai@xinyuanhudong.com</span>
                <span>  联系电话：18612276865</span>
              </p>
              <p class="right_p2">
                  <span>  经营许可证编号：B2-20183981；京B2-20181880</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <img  style="margin-bottom: -5px;" src="@/assets/xyhd/xwgk_ba.png"/>
                    网站备案号：
                  <a href="https://beian.miit.gov.cn/#/Integrated/index"  target="_blank" style="color: black">京ICP备2022009417号-1</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /脚部 -->
  </div>  
</template>

<script>
export default {

}
</script>

<style lang="scss">

// 脚部样式
.footer{
  margin-top: 20px;

  .top{
    /*background-color: #f5f5f5;*/
    height: 93px;

    .wrapper{
      .nav{
        ul{
          display: flex;
          // padding-top: 25px;
          margin-left: 25px;
          li{
            padding: 10px 15px 10px 15px;
            font-size: 14px;
            cursor: pointer;
            margin-top: 10px;
          }
          li:hover{
            background-color: #fff;
          }
        }
      }
      .hots{
        .content_h{
          display: flex;
          margin-left: 40px;
          h3{
            font-size: 14px;
            color: #85360b;
            font-weight: lighter;
          }
          ul{
            display: flex;
            li{
              padding: 10px 15px 10px 15px;
              // width: 135%;
              select{
                /*border: 1px solid #ccc;*/
                background:  no-repeat scroll right center #ffffff;
                color: #383030;
                font-size: 14px;
                width: 155px;
              }
            }
          }
        }
      }
    }
  }
  .bottom{
    height: 100px;
    .wrapper{
      .content_foot{
        display: flex;
        justify-content: center;

        .left{
          margin-top: 20px;
        }
        .right{
          margin-left: 25px;
          margin-top: 20px;
          font-size: 14px;
          color: #1a1818;
          .right_p1{
            margin: 0;
            span{
              margin-left: 25px;
            }
          }
          .right_p2{
            text-align: center;
            margin-left: -50px;
          }
        }
      }
    }
  }
}
</style>