<template>
  <!-- 头部 -->
    <div class="header">
      <!-- logo和搜索框 -->
      <div class="top">
        <div class="wrapper">
          <div class="left">
            <img src="@/assets/xyhd/company_logo.gif" alt="" />
          </div>

          <div class="right">
            <div class="title">
              <li class="z-nav__list">
                <a class="z-nav__item" href="/#/home">首页</a>
                <a class="z-nav__item" href="/#/mediaInfo/index">媒体资源介绍</a>
                <a class="z-nav__item" href="/#/networkMessages/index">网络消息</a>
                <a class="z-nav__item" href="/#/cooperationCases/index">合作案例</a>
                <a class="z-nav__item" href="/#/raltInfo/index">资讯中心</a>
                <a class="z-nav__item" href="/#/charge/index">收费标准</a>
                <a class="z-nav__item" href="/#/companyInfo/index">公司介绍</a>
                <a class="z-nav__item" href="/#/login/index">登录&nbsp|&nbsp注册</a>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /头部 -->
</template>

<script>
import { get, post } from "@/utils/request";
export default {
  data() {
    return {
      categories:[],
    };
  },
  methods: {
    toArticle(item){
      // 编程式导航跳转
      this.$router.push({
        path:"/article",
        query:item
      })
    }
  },
  created() {
  }
}
</script>

<style lang='scss'>

// 头部
.header {
  .top {
    height: 85px;
    background-color: white;
    .wrapper {
      display: flex;
      // 设置主轴排列方式
      justify-content: space-between;
      /*width: 100%;*/
      color: white;
      // line-height: 100px;
      .left {
        padding-top: 18px;
      }
      .middle{
        display: flex;
        color: black;
        width: 400px;
        // 设置主轴排列方式
        margin-bottom: -12px;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        margin-right: 40px;
      }
      .right {
        width: 100%;
        padding-top: 31px;
        display: table-cell;
        margin: 0 auto; text-align: center;
        .title{
          padding-top: 10px;
          a{
            color: black;
          }
          a:hover{
            text-decoration:underline
          }
          .z-nav__list {
            float: right;
            margin: -18px;
            padding: 0;
            list-style: none;
            /*margin-right: 5%;*/
          }

          .z-nav__list .z-nav__item {
            position: relative;
            display: block;
          }

          .z-nav__list > .z-nav__item {
            display: block;
            float: left;
            min-height: 50px;
            padding: 0 10px;
            line-height: 50px;
            font-size: 16px;
          }
        }
      }
    }
  }

}
// /头部

</style>