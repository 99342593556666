
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path:"/",
    redirect:"/home"
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/Home.vue')
  },
  //  收费标准
  {
    path: '/charge/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../charge/Index.vue')
  },
  //  网络消息
  {
    path: '/networkMessages/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../networkmessages/Index.vue')
  },
  //  公司介绍
  {
    path: '/companyinfo/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../companyinfo/Index.vue')
  },
  //  合作案例
  {
    path: '/cooperationcases/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../cooperationcases/Index.vue')
  },
  //  媒体资源介绍
  {
    path: '/mediainfo/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../mediainfo/Index.vue')
  },
  //  登录
  {
    path: '/login/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../login/Index.vue')
  },
  //  注册
  {
    path: '/register/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../register/Index')
  },
  //  资讯这些
  {
    path: '/raltInfo/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../raltinfo/Index')
  },
  //  支付
  {
    path: '/pay/index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../pay/Index')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
