<template>
    <!--内容-->
    <div class="container im-content" style="display: block;">
        <div class="center-block list com bangong">
            <ul>
                <li class="li01" >
                    <span></span>
                    <b style=""></b>
                    <em>厂商资源</em>
                </li>
                <li class="li02" >
                    <span></span>
                    <b></b>
                    <em>市场资源</em>
                </li>
                <li class="li03" >
                    <span></span>
                    <b></b>
                    <em>信息流资源</em>
                </li>
                <li class="li05" >
                    <span></span>
                    <b></b>
                    <em>短信资源</em>
                </li>
                <li class="li04" >
                    <span></span>
                    <b></b>
                    <em>DSP资源</em>
                </li>
            </ul>
        </div>
        <div class="product">
            <div class="product_content">
                <span><b><a target="_blank">合作初期起，提供一站式服务，未合作伙伴提供多方面支持，结合产品、市场、资源，满足合作伙伴的不同需求。 </a></b></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BodyPart",
        data(){
            return{

            };
        },
        created() {
        },
        methods:{

        }
    }
</script>

<style lang="scss">
    /*内容与产品*/
    .im-content {
        margin-top: 5%;
    }
    .im {
        width: 1138px;
        height: 175px;
        margin: 0 auto;
        background: url(../assets/xyhd/bg1.png) no-repeat;
    }
    @media only screen and (min-width: 1000px) and (max-width: 1100px) {
        .im {
            width: 100%;
        }
    }
    .im_content {
        width: 512px;
        height: 175px;
        cursor: pointer;
    }
    .list {
        margin:5% auto;
    }
    .list ul {
        overflow: hidden;
        clear: both;
    }
    .list li {
        float: left;
        width: 20%;
        text-align: center;
        position: relative;
    }
    .list ul li span {
        border-radius:100px;
        display: block;
        width: 110px;
        height: 110px;
        margin-left: 60px;
        margin-bottom: 20px;
        cursor: pointer;
        position:absolute;
        left:-1px;
        top:0;
    }
    .list ul li b {
        line-height: 110px;
        /*	background: rgba(255,255,255,0.7);
        background:#00a0e9;	*/
        color: #ffffff;
    }
    .list ul li em {
        font-style:normal;
        display:block;

    }
    .com ul li b {
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        transition: all 1s;
        -moz-transition: all 1s;
        -webkit-transition: all 1s;
        filter:alpha(opacity=0);
    }

    @media \0screen\,screen\9 {
        /*.com ul li b {
            background: #000000;
            filter:alpha(opacity=0);
            -moz-opacity:0;
            -khtml-opacity: 0;
            *zoom: 1;
        }*/
        .com ul li b {
            background: #000000;
            filter:alpha(opacity=0);
            -moz-opacity:0;
            -khtml-opacity: 0;
            *zoom: 1;
        }
    }
    @media \0screen\,screen\9 {
        .list ul li b {
            /*background: #FFF;*/
        }
    }
    @media only screen and (min-width: 1000px) and (max-width: 1100px) {
        .im-content .com ul li b {
            left: 20px;
        }
    }
    .list ul li a {
        text-align: center;
        color: #333333;
        font-size: 18px;
        display: block;
    }
    .list ol li {
        display: none;
    }
    .list li em {
        padding:130px 20px 0 20px;
    }

    .product {
        position: relative;
    }
    .product_content {
        background: #f4f4f4;
        border: 1px #c1c1c1 solid;
        padding: 40px 20px;
        color: #000000;
        font-size: 18px;
        cursor: pointer;
        text-align:center;

    }
    .product_content a{color: #000000;}
    .product_content a:hover{color: #00a0e9;}
    .product_content hover{
        color:#red
    }

    .list li.li01 em {
        background: url(../assets/xyhd/img1-1.gif) no-repeat center 0;
    }
    .list li.li02 em {
        background: url(../assets/xyhd/img1-2.gif) no-repeat center 0;
    }
    .list li.li03 em {
        background: url(../assets/xyhd/img1-3.gif) no-repeat center 0;
    }
    .list li.li04 em {
        background: url(../assets/xyhd/img1-4.gif) no-repeat center 0;
    }
    .list li.li05 em {
        background: url(../assets/xyhd/img1-5.gif) no-repeat center 0;
    }
</style>