<template>
    <div>
        <div class="content_ralt">
            <div class="text-full">
                <ul class="news-list">
                    <li>
                        <div class="image-box">
                            <img class="article-img" src="@/assets/xyhd/cpc.jpg" alt="">
                        </div>
                        <div class="article-text">
                            <a href="/#/pay/index">
                                <h3>信息流投放</h3>
                                <p class="description">CPC是按照点击计费，是根据广告被点击的次数来决定，例如某个广告单次点击价格为0.7元，则CPC=0.7。此计费形式相当于竞价，出价可自主决定。OPPO、华为、抖音、头条、快看漫画等等各个媒体信息流投放</p>
                                <p>起投单价：0.1元</p>
                                <p>合作模式：竞价</p>
                                <p>更多>></p>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="image-box">
                            <img class="article-img" src="@/assets/xyhd/cpa.jpg" alt="">
                        </div>
                        <div class="article-text">
                            <a href="/#/pay/index">
                                <h3>效果投放</h3>
                                <p class="description">CPA是按照行动付费，一种按广告投放实际效果计价方式的广告，这里的行动指表单、咨询、注册、下载、下单等行为，只要用户发生广告主与媒体/代理商约定好的行为，就会收取广告费用。</p>
                                <p>CPA：2元起</p>
                                <p>更多>></p>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="image-box">
                            <img class="article-img" src="@/assets/xyhd/cpd.jpg" alt="">
                        </div>
                        <div class="article-text">
                            <a href="/#/pay/index">
                                <h3>下载投放</h3>
                                <p class="description">CPD是一种下载付费广告，根据广告被下载的次数来收费，如应用商店、积分墙、流量联盟都是比较常见的。以豌豆荚、金立、魅族、努比亚等等各个媒体效果投放。</p>
                                <p>CPD：2元起</p>
                                <p>更多>></p>
                            </a>
                        </div>
                    </li>

                    <li>
                        <div class="image-box">
                            <img class="article-img" src="@/assets/xyhd/cpm.jpg" alt="">
                        </div>
                        <div class="article-text">
                            <a href="/#/pay/index">
                                <h3>展示投放</h3>
                                <p class="description">CPM是一种按照展示计费，是按照广告每展示1000次收费，例如头条每1000次广告展示是4元，则CPM=4。媒体有高铁管家、航班管家、快看漫画等等</p>
                                <p >CPM：千次展示（请与商务联系）</p>
                                <p>更多>></p>
                            </a>
                        </div>
                    </li>

                    <li>
                        <div class="image-box">
                            <img class="article-img" src="@/assets/xyhd/cpt.jpg" alt="">
                        </div>
                        <div class="article-text">
                            <a href="/#/pay/index">
                                <h3>时长投放</h3>
                                <p class="description">CPT是一种按照时长来计费，如“一个星期、一个月某个广告位费用是固定的”这种模式来收费，就相当于以固定价格买断某一段时间的某个广告位展示。</p>
                                <p >CPT：按时长结算（请与商务联系）</p>
                                <p>更多>></p>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RaltInfo"
    }
</script>

<style lang="scss">
    .content_ralt {
        max-width: 1160px;
        min-width: 960px;
        /*min-height: 200px;*/
        padding: 0 0 0;
        margin: 0 auto;
        /*text-align: center;*/
        .text-full {
            font-size: 16px;
            line-height: 34px;
            letter-spacing: 1.7px;
            text-indent: 2em;
            padding: 20px 0 60px;
        }
        .news-list {
            padding: 10px 40px 0;
        }
        .news-list li {
            padding: 20px 0;
            overflow: hidden;
            border-bottom: 1px dotted #c2c2c2;
            position: relative;
        }
        .news-list li .article-img {
            float: left;
            width: 180px;
            overflow: hidden;
            height: 124px;
            padding: 5px;
            border: solid 1px #ccc;
        }
        .news-list li .article-text {
            margin-left: 220px;
        }
        p {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            margin:0px;
        }

        .article-text a:hover{color: #00a0e9;}

        h3 {
            display: block;
            font-size: 1.17em;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
        }
        .news-list li .article-text p {
            font-size: 16px;
        }
    }
</style>