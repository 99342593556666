<template>
    <div>
        <div class="image-container">
            <img src="@/assets/xyhd/company_info_banner.png" style="height: 300px;width: 100%">
            <div class="text-overlay">
                <h2>{{title}}</h2>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderImage",
        props:['title'],
        data(){
            return {}
        },

    }
</script>

<style lang="scss">
    .image-container {
        position: relative;  /* 使容器成为一个相对定位的容器 */
        line-height: 20px;
    }

    .text-overlay {
        position: absolute;  /* 让文字层叠在图片上 */
        top: 50%;  /* 相对于容器上边缘垂直居中 */
        left: 50%;  /* 相对于容器左边缘水平居中 */
        transform: translate(-50%, -50%);  /* 通过负的50%的偏移量来实现居中效果 */
        color: #fff;  /* 设置文字的颜色 */
        font-size: 26px;
        text-align: center;  /* 设置文字水平居中 */
    }

    .text-overlay h2 {
        margin: 0;  /* 去除文字的上下间距 */
    }
</style>