<template>
    <div style="margin-left: 15%">
        <div class="image__lazy">
            <el-image v-for="url in urls" :src="url" ></el-image>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ImageList",
        props:['urls'],
        data(){
            return {}
        },
        created() {},
        methods() {}
    }
</script>

<style lang="scss">
    .image__lazy {
        width: 83%;
        height: 80%;
    }
</style>