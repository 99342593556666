<template>
    <div class="content">
        <div class="top">
            <!-- 轮播图 -->
            <div class="swipes">
                <div class="wrapper">
                    <el-carousel indicator-position="outside" :interval="4000" :height="dataHeight">
                        <el-carousel-item v-for="item in swipes" :key="item.id">
                            <img :src="item.url" alt="" style="width: 100%; height: 100%;"/>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Carousel",
        props: {
            dataHeight: {
                type: String,
                default: '360px'
            }
        },
        data() {
            return {
                swipes: [
                    {'id': 0, 'url': require("@/assets/xyhd/banner1.jpg")},
                    {'id': 1, 'url': require("@/assets/xyhd/banner2.jpg")},
                    {'id': 2, 'url': require("@/assets/xyhd/banner3.jpg")},
                ],
                categories: [],
            };
        },
    }
</script>

<style lang="scss">
    .content {
        height: 400px;
        .swipes {
            .wrapper{
                .el-carousel {

                }
                .el-carousel__item img {
                    color: #475669;
                    font-size: 18px;
                    opacity: 0.75;
                    line-height: 300px;
                    margin: 0;
                }
                .el-carousel__item:nth-child(2n) {
                    background-color: #99a9bf;
                }
                .el-carousel__item:nth-child(2n+1) {
                    background-color: #d3dce6;
                }
            }
        }
    }
</style>